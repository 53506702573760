<template>
  <div v-if="!loading">
    <car-additional-data-save-modal
      v-if="showSaveModal"
      :show="showSaveModal"
      :car-id="$route.params.carId"
      @close="saveModalClosed"
    />
    <ca-header heading="Weitere Daten zum Fahrzeug hinzufügen">
      <template #buttonSpace>
        <button
          v-if="(curStep > 1) && (curStep < 6)"
          type="button"
          class="btn btn-secondary mr-2"
          @click="curStep = curStep - 1"
        >
          zurück
        </button>
        <button
          v-if="curStep < 5"
          type="button"
          class="btn btn-primary"
          @click="nextStep()"
        >
          weiter
        </button>
        <button
          v-if="curStep === 5"
          type="button"
          class="btn btn-primary"
          @click="save()"
        >
          Abschließen
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-progress-bar
            class="mb-3"
            :value="curStep"
            :max="5"
          />
          <components
            :is="`step-${curStep}`"
            v-if="curStep < 6"
            :additional-data="additionalData"
          />
          <step-5 v-if="curStep === 6" />
        </div>
      </div>
    </div>
    <dev-info :data="{additionalData}" />
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import CaHeader from '@/components/Layout/Header'
import CaProgressBar from '@/components/CarAdditionalDataProgressBar'
import CarAdditionalDataSaveModal from '@/components/Modals/CarAdditionalDataSaveModal'
import DevInfo from '@/components/DevInfo'
import Step1 from '@/components/CarAdditionalData/Step1'
import Step2 from '@/components/CarAdditionalData/Step2'
import Step3 from '@/components/CarAdditionalData/Step3'
import Step4 from '@/components/CarAdditionalData/Step4'
import Step5 from '@/components/CarAdditionalData/Step5'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'AdditionalCarData',
  components: {
    CaHeader,
    CaCard,
    CaProgressBar,
    CarAdditionalDataSaveModal,
    DevInfo,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  data: function () {
    return {
      showSaveModal: false,
      curStep: 1,
      loading: true,
      additionalData: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    saveModalClosed () {
      // zum Fahrzeug
      this.$router.push({ name: 'CarDetail', params: { carId: this.$route.params.carId } })
      this.showSaveModal = false
    },
    async nextStep () {
      const valid = await this.$validator.validateAll()
      if (!valid) {
        return
      }
      this.curStep++
    },
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending || !valid) {
          return
        }
        this.savePending = true
        await this.additionalData.save({
          query: { $client: { carId: this.$route.params.carId } }
        })
        this.curStep++
        this.showSaveModal = true
      } catch (error) {
        console.error(error)
      } finally {
        this.savePending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        const carId = this.$route.params.carId
        const car = this.$store.getters['car/get'](carId) || await this.$store.dispatch('car/get', carId)
        if (car.carAdditionalInfoId) {
          this.additionalData = await this.$store.dispatch('car-additional-info/get', car.carAdditionalInfoId)
        } else {
          this.additionalData = new this.$FeathersVuex.api.CarAdditionalInfo()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>

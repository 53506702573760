<template>
  <modal
    v-if="show"
    size="md"
    title="Fahrzeug wurde gespeichert"
    @close="$emit('close', $event)"
  >
    <template #body>
      <ca-alert
        variant="success"
        not-closeable
        icon="none"
        :padding="3"
        class="mb-4"
        rounded
      >
        <div class="row my-2">
          <div class="col-auto text-center">
            <i class="fal fa-3x left-icon fa-check-circle text-white" />
          </div>
          <div class="col">
            <h4 class="text-white">
              Speichern erfolgreich
            </h4>
            <p>
              Ihr Fahrzeug wurde erfolgreich zu Ihrem Fahrzeugbestand hinzugefügt.
            </p>
            <router-link
              class="text-white"
              :to="{ name: 'CarDetail', params: { carId } }"
            >
              <i class="far fa-angle-right" /> zum Fahrzeug
            </router-link>
          </div>
        </div>
      </ca-alert>
      <div class="row mb-4 p-3">
        <div class="col-auto text-center">
          <i class="fal fa-3x left-icon fa-car text-primary" />
        </div>
        <div class="col">
          <h4>
            Weiteres Fahrzeug hinzufügen
          </h4>
          <p>
            Hier haben Sie die Möglichkeit ein weiteres Fahrzeug zu Ihrem Fahrzeugbestand hinzuzufügen.
          </p>
          <router-link
            :to="{name: 'CarCreate'}"
            class="btn btn-primary"
          >
            <i class="far fa-plus" /> weiteres Fahrzeug hinzufügen
          </router-link>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  name: 'CarAdditionalDataSaveModal',
  components: {
    Modal
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    carId: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.left-icon{
  width: 50px;
}
</style>

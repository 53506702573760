<template>
  <div>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${width}%`"
        :aria-valuenow="value"
        aria-valuemin="0"
        :aria-valuemax="max"
      />
    </div>
    <span
      v-if="value < (max + 1)"
      class="small"
    >Schritt <span class="font-weight-bold">{{ value }}</span> von <span class="font-weight-bold">{{ max }}</span></span>
    <span
      v-if="value > max"
      class="small font-weight-bold"
    ><i class="fas fa-check-circle" /> Fertig</span>
  </div>
</template>
<script>
export default {
  name: 'CarAddionalDataProgressbar',
  props: {
    max: {
      default: 10,
      type: Number
    },
    value: {
      required: true,
      type: Number
    }
  },
  computed: {
    width () {
      return 100 / this.max * (this.value - 1)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.progress{
  height: 5px;
  background-color: $primary-light;
  .progress-bar{
    height: 5px;
  }
}
</style>
